import React from "react";
import "./Testimony.css";
import { testimonialsData } from "../../data/testimonialsData";
import { Swiper, SwiperSlide,useSwiper } from 'swiper/react';
import 'swiper/css';
import {motion} from 'framer-motion'

export default function Testimony(params) {
    return(
        <section className="t-wrapper " >
            <div className="t-container">

            <Swiper >
            <SliderButton/>
                {
                    testimonialsData.map((data,i)=>(
                        <SwiperSlide  key={i}>
                            <div className="testimony">
                            <div className="l-side flexcolumn">
                                  <span className="orange">TESTIMONIALS</span>
                                  <br></br>
                                  <span className="primarytext stroke-text">WHAT THEY</span>
                                  <span className="primarytext">SAY ABOUT US</span>
                                  <br></br>
                                  <br></br>
                                  <span className="truth">{data.review}</span>
                                  <br></br>
                                  <br></br>
                                  <br></br>
                                   <div className="flex">
                                    <span className="secondarytext o-text">
                                        {data.name}
                                    </span>
                                    <span className="secondarytext">-  {data.status}</span>
                                   </div>
                                   <br></br>
                              </div>

                              <div className="r-side pic">
                                <div className="m-img">{data.image}</div>
                                <motion.div className="b1"
                                     intial={{left:"-8rem", top:"0rem"}}
                                     whileInView={{left:"-6rem",top:"1rem"}}
                                     transition={{
                                         duration:3,
                                         type: "spring"
                                 
                                     }}
                                ></motion.div>
                                <motion.div className="b2"
                                 intial={{left:"-2.3rem", top:"4.8rem"}}
                                whileInView={{left:"-3.7rem",top:"3.7rem"}}
                                 transition={{
                                     duration:3,
                                     type: "spring"
                             
                                 }}
                                
                                ></motion.div>
                              </div>
                            </div>
                             
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            </div>
        </section>
    )
};

const SliderButton=()=>{
    const swiper=useSwiper();
    return(
        <div className="flexcenter r-buttons">
           <button onClick={()=>swiper.slidePrev()}>&lt;</button>
           <button  onClick={()=>swiper.slideNext()}>&gt;</button>
        </div>
    )
}