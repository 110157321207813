import React, { useState } from "react";
import './Header.css'
import CountUp from "react-countup";
import {Link} from "react-scroll";
import {motion} from 'framer-motion'
const transition={type:'spring',duration:3}

export default function Header(params) {
    const mobile=window.innerWidth<=790 ?true : false;
    const [menuopen,setmenuop]=useState(false);
    return(
        <section className=" hero" id='home'>
            {/* left side */}
            <div className="l-side flexcolumn">
                <div className="header  flex">
                    <span className="title">
                        THE 
                        <br></br>  GYM BOT
                    </span>
                    <div className="mside">
                    {menuopen===false && mobile===true?(
                        <div style={
                            {backgroundColor: 'var(--appColor)',padding: '10px',position:'fixed',right:'2rem'}
                        }
                        onClick={()=>setmenuop(true)}
                        >
                            <img src="./images/bars.png" style={{width:'1.5rem',height:'1.5rem'}}></img>
                        </div>
                        ):(
                             <div className="heading" onClick={()=>setmenuop(false)}>
                            <a href="">
                            <Link
                              to='home'
                              span={true}
                              smooth={true}
                            >Home</Link>
                            </a>
                            <a href="">
                            <Link
                              to='programs'
                              span={true}
                              smooth={true}
                            >Programs</Link>
                            </a>
                            <a href="">
                            <Link
                              to='why'
                              span={true}
                              smooth={true}
                            >Why us</Link>
                            </a>
                            <a href="">
                            <Link
                              to='plans'
                              span={true}
                              smooth={true}
                            >Plans</Link>
                            </a>

                            <a href=""><Link
                              to='testimony'
                              span={true}
                              smooth={true}
                            >Testimonials</Link></a>
                        </div>
                        )
                        } 
                    </div>
                     
                    
                </div>
                <br></br>
                <br></br>
                <br></br>
                <div className="hero flexcolumn">
                    <div className="dec ">
                        <motion.div className="orangeslider"
                        intial={{left:"2rem"}}
                        whileInView={{left:"4px"}}
                        transition={{
                            duration:2,
                            type: "spring"
                    
                        }}
                        
                        ></motion.div>
                        <span className="secondarytext"> THE BEST FITNESS CLUB IN THE CITY</span>
                        </div>
                     <div className="content pad">
                        <div className="hero-text">
                            <span className="stroke-text">SHAPE</span> YOUR
                            <br></br>
                            IDEAL BODY
                        </div>
                       <br></br>
                        <span className="thirdtext flex">In here we will help to reduce your fat
                           and give u an ideal body in your life and enjoy your life to fullest. 
                        </span>
                        </div>
                     <div className="counter flex ">
                           <div className="meter">
                           <div className="secondarytex">
                                  <span>+</span>
                                 <CountUp start={180} end={200} duration={3}/>
                             </div>

                                <span>
                                  EXPERT COACHES
                                </span>
                           </div>
                           <div className="meter">
                           <div className="secondarytex">
                                  <span>+</span>
                                 <CountUp start={950} end={982} duration={3}/>
                             </div>

                                <span>
                                 MEMBERS JOINED
                                </span>
                           </div>
                           <div className="meter">
                           <div className="secondarytex">
                                  <span>+</span>
                                 <CountUp start={40} end={50} duration={3}/>
                             </div>

                                <span>
                                 FITNESS PROGRAM
                                </span>
                           </div>
                     </div> 
                     <br></br>
                     <div className="flex counter pad">
                        <button className="get">Get Started</button>
                        <button className="learn">Learn More</button>
                    </div>     
                </div>

            </div>
            {/* rightside */}
            <div className="r-side flexcolumn">
            <motion.div className="calory "
                           intial={{left:"-110%"}}
                           whileInView={{left:"-82%"}}
                           transition={{
                               duration:3,
                               type: "spring"
                       
                           }}
                       >
                      <img src="./images/calories.png"/>
                      <span >Calories<br></br>
                         burned
                        <br></br>
                        220kcal
                      </span>
                    </motion.div>
                    
                          <div className="orangeback"></div>
                       <div className="hrt">
                       <div className="flexEnd ">
                       <button className="button  ">Join now</button>
                       </div>
                       <br></br>
                       <motion.div className="heart flexcolumn "
                           intial={{left:"2rem"}}
                           whileInView={{left:"2px"}}
                           transition={{
                               duration:2,
                               type: "spring"
                       
                           }}
                       >
                            <img src="./images/heart.png" alt=""  />
                             <span>Heart Rate</span>
                             <span>116 bpm</span>
                       </motion.div>
                       </div>
          
                        
                          
                         <br></br>
                         <br></br>
                         <div className="flex">
                       
                       <div className="hero-img">
                               <motion.img src="./images/hero_image_back.png"
                                   intial={{left:"-25%"}}
                                   whileInView={{left:"-48%"}}
                                   transition={{
                                       duration:3,
                                       type: "spring"
                               
                                   }}
                                  
                               ></motion.img> 
                               <motion.img src="./images/hero_image.png"
                                  intial={{opacity: 0}}
                                  animate={{opacity:1}}
                                  transition={{
                                      duration:2}}
                               ></motion.img>            
                          </div>
                      
                       
                         </div>
                          
                          
                       
                               
                      

            </div>


        </section>

    )
};
