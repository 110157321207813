import React from "react";
import './Reasons.css';

export default function Reasons(params) {
   return(
     <secton className="r-menu pad" id='why'>
          {/* left side */}
         <div className="l-img">
            <div className="left">
                <img src="./images/image1.png"></img>
            </div>
            <div className="right flexcolumn">
                <img src="./images/image2.png"></img>
                <div className="b-img">
                    <img src="./images/image3.png" alt="" />
                    <img src="./images/image4.png" alt="" />
                </div>
            </div>
        </div>

        {/* right side  */}
        <div className="r-sides flexcolumn">
            <span className="orange">SOME REASONS</span>
            <br></br>
            
            <div className="primarytext flex">
                <span className="stroke-text">WHY </span>
                <span>CHOOSE </span>
                <span>US?</span>
                
            </div>
            <br></br>
            <div className=" bull">
                <div className="bulletins">
                    <img src="./images/tick.png"></img>
                    <span >OVER 140+ EXPERT COACHS</span>
                </div>
                <div className="bulletins">
                    <img src="./images/tick.png"></img>
                    <span >TRAIN SMARTER AND FASTER THAN BEFORE</span>
                </div>
                <div className="bulletins">
                    <img src="./images/tick.png"></img>
                    <span >1 FREE PROGRAM FOR NEW MEMBER</span>
                </div>
                <div className="bulletins">
                    <img src="./images/tick.png"></img>
                    <span >RELIABLE PARTNERS</span>
                </div>
            </div>
           <br></br>
            <span className="text">OUR PARTNER</span>
            <div className="flex sponsor">
               <img src="./images/nike.png"></img>
               <img src="./images/nb.png"></img>
               <img src="./images/adidas.png"></img>  
            </div>
        </div>
     </secton>
   )
};
