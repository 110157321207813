import React from "react";
import './Plans.css';
import {plansData} from "../../data/plansData"

export default function Plans(params) {
    return(
        <section className="main " id='plans'>
            <div className="pro-head">
                <span className="stroke-text">READY TO START</span>
                <span>YOUR JOURNEY</span>
                <span className="stroke-text">NOW WITHUS</span>
            </div>
            <br></br>
            <br></br>
            <div>
             <div className="grad pad">
                <div className="grad1">
                  <div className="orange-grd"></div>
                </div>
                 <div className="grad2">
                 <div className="orange-grd"></div>
                 
                 </div>
             
             </div>

            <div className="plancard">
           
           {
            plansData.map((card,i)=>(
                <div className="lists">
                         <div className="plan" key={i}>{card.icon} </div>
                         <div className="secondarytext" >{card.name}</div>
                         <div className="primarytext">{card.price}</div>
                         <div className="features flexcolumn">
                            {
                                card.features.map((no,i)=>(
                                    <div className="feature flex">
                                        <img src="./images/whiteTick.png"/>
                                        <span>{no}</span>
                                        <br></br>
                                    </div>
                                ))
                            }
                         </div>
                         <div className="thirdtext">see more benefits -{">"} </div>
                         <button>Join now</button>

                </div>
            ))
           }
        </div>

            </div>
            
        </section>
    )
};
