import Header from "./Components/Header/Header";
import Programs from "./Components/Programs/Programs";
import Reasons from "./Components/Reasons/Reasons";
import Plans from "./Components/Plans/Plans";
import Testimony from "./Components/Testimony/Testimony";
import Join from "./Components/Join/Join";
function App() {
  return (
    <div className="App">
      <div>
        <div className="orange-grd"></div>
        <Header/>
      </div>
        <Programs/>
        <Reasons/>
        <Plans/>
        <Testimony/>
        <Join/>
    </div>
  );
}

export default App
