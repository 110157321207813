import React from "react";
import './Programs.css';
import {programsData} from "../../data/programsData"
export default function Programs(params) {
    return(
        <section className="main flexcolumn" id='programs'>
            <br></br>
            <div className="pro-head ">
                <span className="stroke-text ">EXPLORE OUR </span>
                <span>PROGRAMS</span>
                <span className="stroke-text">TO SHAPE YOU</span>
            </div>
            <br></br>
            <br></br>
            <div className="prog-list">
                   {
                    programsData.map((card,i)=>(
                        <div className="list">
                            
                            <div className="p-icon">{card.image}</div>
                            <div className="p-head">{card.heading}</div>
                            <div className="p-content">{card.details}</div>
                           
                            <div className="join  ">
                                <span className="secondaryText">Join now</span>
                                 <img src="./images/rightArrow.png"></img>
                            </div>
                            
                          </div>   
                    ))
                   }
                </div>
           
        </section>
    )
};
