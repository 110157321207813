import React from "react";
import './Join.css';


export default function Join(params) {
    return(
        <section className="foot " id='join'>
                  <div className="j-wrapper  ">
        <div className="joins">
            <span className=" prim">Get Ready to join </span>
            <span className="sec">Contact us through mail by clicking below box
             and choose confortable program that suits you
            </span>
            <button className="button">
                <a href="mailto:ratnakumar48070@gmail.com">Contact us</a>
               </button>
        </div>
         </div>
         <br></br>
        <div className="footgrad">
        <div className="footer">
            <img src="./images/github.png"></img>
            <img src="./images/linkedin.png"></img>
            <img src="./images/instagram.png"></img>
        </div>
        <div className="white-grd"></div>
        <div className="footgrad thirdtext">
            <span>Developer contact</span>
            <span>Ashishkumar</span>
            <span>Mail through above Get Ready box</span>
        </div>
        </div>

      
        </section>
   
    )
};
